.about-section {
    &__section {
        @include large {
            display: flex;
            justify-content: center;
        }

        &-image {
            background-color: $color-secondary;
            border: 1px solid $color-secondary;
            height: 300px;
            transform: skew(3deg, -3deg);
            transition: all 0.75s ease;
            width: 227px;

            &:hover {
                transform: skew(0);
            }

            &-wrapper {
                border: 1px solid $color-secondary;
                height: 300px;
                margin: 0 auto 50px;
                width: 227px;
            }
        }

        &-content {
            padding: 0 20px 0;
            text-align: center;

            @include large {
                max-width: 500px;
                padding: 0 0 0 50px;
                text-align: left;
            }

            &--callout {
                font-size: 18px;
                line-height: 27px;
            }
        }
    }

    &__sections {
        margin: 50px 0 75px;

        @include large {
            margin: 50px 50px 75px;
        }
    }

    .intro {
        font-size: 48px;
    }

    h2 {
        text-align: center;

        @include large {
            text-align: left;
        }
    }
}
