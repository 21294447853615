.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .spinner:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 0%;
    border: 6px solid $color-secondary;
    border-color: $color-secondary;
    animation: spinner 1.5s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
