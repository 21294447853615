.side-navigation {
    @include large {
        left: 0;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }

    ul {
        display: flex;
        filter: invert(1);
        justify-content: space-between;
        list-style: none;
        margin: 0 30px;
        padding: 0;
        text-align: center;
        width: 75px;

        @include large {
            flex-direction: column;
            margin: 10px;
            padding: 10px 0;
            width: 50px;
        }

        .small-navigation.active & {
            display: flex;
        }
    }

    .linked {
        filter: grayscale(1);
    }

    img {
        height: 30px;
        margin-bottom: 15px;
        width: 30px;
    }
}
