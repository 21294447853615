.section {
    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        margin: 0 auto 20px;
        text-align: center;

        li {
            margin: 5px 10px;
        }

        button {
            padding: 0 0 5px;
            transition: color 0.4s ease;

            &.active,
            &:hover {
                color: $color-secondary;
            }
        }
    }
}
