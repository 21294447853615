body {
    font-size: 16px;
    line-height: 24px;
}

h1 {
    font-size: 48px;
    line-height: 54px;
    margin: 15px 0;
}

h2 {
    font-size: 18px;
    margin: 25px 0 10px;
    text-transform: uppercase;
    font-weight: normal;
}

p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
}
