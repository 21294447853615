.projects {
    a {
        // padding-bottom: 0;
    }

    &__project {
        &-wrapper {
            border-bottom: 1px dotted #9120a6;
            margin: 0 auto 30px;
            padding: 20px;
            text-align: center;
            width: 75%;

            &:last-of-type {
                border-bottom: none;
                margin-bottom: 60px;
            }
        }

        &-inprogress {
            text-transform: uppercase;
        }

        &-image {
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
            height: 100%;
            width: auto;
            
            &-link {
                background: none;
                display: inline-block;
                height: 200px;
                margin-bottom: 25px;
            }
        }

        &-title {
            margin-top: 0;
            text-align: center;
        }

        &-description {}

        &-inline-list {
            display: flex;
        }
    }
}